import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { MultiSelect, Select } from "@lrewater/lre-react";
import {
  findIndexOfLongestArray,
  groupByValue,
  lineColors,
} from "../../../utils";
import Button from "@material-ui/core/Button";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Divider as MuiDivider,
  Grid as MuiGrid,
  lighten,
  Typography as MuiTypography,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import WaterYearLineChart from "../../../components/graphs/WaterYearLineChart";

import { scaleLinear } from "d3-scale";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const ReachGainsComparison = () => {
  const saveRef = useRef(null);

  const [filterValues, setFilterValues] = useState({
    location: "",
    series: [
      "Minimum",
      "Median",
      "Maximum",
      (new Date().getFullYear() + 1).toString(),
      new Date().getFullYear().toString(),
    ],
  });

  const { data: Locations } = useQuery(
    ["wy-analysis-dropdown-locations-assoc-param/1"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-dropdown-locations-assoc-param/1`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: Series } = useQuery(
    ["wy-analysis-dropdown-series"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-dropdown-series`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterValues((prevState) => {
      let newValues = { ...prevState };

      newValues[name] = value;

      return newValues;
    });
  };

  const { data, error, isFetching, refetch } = useQuery(
    [`api/reach-gains-report/${filterValues.location}/${filterValues.series}`],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/reach-gains-report/${filterValues.location}/${filterValues.series}`
        );

        const groupedData = groupByValue(data, "year_num");

        return groupedData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const [dataColumns, setDataColumns] = useState([]);
  const {
    data: dataTable,
    // error: errorTable,
    isFetching: isFetchingTable,
    refetch: refetchTable,
  } = useQuery(
    [`api/reach-gains-report/${filterValues.location}`],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/reach-gains-report/${filterValues.location}`
        );

        const groupedData = groupByValue(data, "month_abbrev");
        const columns = [
          {
            title: "Month",
            field: "month_abbrev",
            cellStyle: {
              whiteSpace: "nowrap",
              padding: "3px 3px 3px 3px",
              position: "sticky",
              left: 0,
              background: "white",
              fontWeight: 900,
            },
            headerStyle: {
              padding: "3px 3px 3px 3px",
              fontWeight: 900,
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 11,
            },
            // defaultSort: "asc",
            position: "sticky",
            left: 0,
            background: "white",
          },
        ];

        const crossTabbedData = groupedData.map((month) => {
          const obj = {};

          let min = null;
          let max = null;
          let median = null;
          let values = []; // An array to store the non-excluded values

          month.forEach((item) => {
            if (!["Median", "Minimum", "Maximum"].includes(item.year_num)) {
              const value = item.reach_gain_cfs * 1.983;
              if (value !== 0) {
                values.push(value);
                if (min === null || value < min) {
                  min = value;
                }

                if (max === null || value > max) {
                  max = value;
                }
              }
            }
          });

          // Sort the values in ascending order
          values.sort((a, b) => a - b);

          if (values.length > 0) {
            if (values.length % 2 === 0) {
              // If there is an even number of values, calculate the average of the two middle values
              const middleIndex1 = values.length / 2 - 1;
              const middleIndex2 = values.length / 2;
              median = (values[middleIndex1] + values[middleIndex2]) / 2;
            } else {
              // If there is an odd number of values, simply take the middle value
              const middleIndex = Math.floor(values.length / 2);
              median = values[middleIndex];
            }
          }

          const colorScale = scaleLinear()
            .domain([min, median, max]) // Set the domain of your scale to your min and max values
            .range(["#E67C73", "transparent", "#57BB8A"]); // Set the range of colors you want to interpolate between

          obj["month_abbrev"] = month[0].month_abbrev;
          month.forEach((item) => {
            if (item?.year_num) {
              obj[item.year_num] = item.reach_gain_cfs * 1.983;
              if (!columns.find((col) => col.title === "" + item.year_num)) {
                columns.push({
                  title: "" + item.year_num,
                  field: "" + item.year_num,
                  render: (rowData) =>
                    rowData[item.year_num] === 0 || rowData[item.year_num]
                      ? `${(+rowData[item.year_num].toFixed(
                          0
                        )).toLocaleString()}`
                      : null,
                  cellStyle: (e, rowData) => {
                    return {
                      padding: "3px 3px 3px 3px",
                      backgroundColor: !rowData[item.year_num]
                        ? "black"
                        : ["Median", "Minimum", "Maximum"].includes(
                            item.year_num
                          ) ||
                          ["Annual Total", "May - Sept Total"].includes(
                            rowData.month_abbrev
                          )
                        ? "white"
                        : colorScale(rowData[item.year_num]),
                      color: "black",
                      textAlign: "center",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    };
                  },
                  headerStyle: {
                    padding: "3px 3px 3px 3px",
                    textAlign: "center",
                    fontWeight: 900,
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  },
                });
              }
            }
          });
          return obj;
        });

        const annualTotal = {};
        const maySeptTotal = {};
        const monthsMayToSept = ["May", "Jun", "Jul", "Aug", "Sep"];

        crossTabbedData.forEach((monthData) => {
          for (const key in monthData) {
            if (key !== "month_abbrev" && !isNaN(monthData[key])) {
              // Calculate the "Annual Total"
              if (!annualTotal[key]) {
                annualTotal[key] = 0;
              }
              annualTotal[key] += monthData[key];

              // Calculate the "May - Sept Total"
              if (monthsMayToSept.includes(monthData.month_abbrev)) {
                if (!maySeptTotal[key]) {
                  maySeptTotal[key] = 0;
                }
                maySeptTotal[key] += monthData[key];
              }
            }
          }
        });

        // Add the "May - Sept Total" object to the array
        maySeptTotal["month_abbrev"] = "May - Sept Total";
        crossTabbedData.push(maySeptTotal);

        // Add the "Annual Total" object to the array
        annualTotal["month_abbrev"] = "Annual Total";
        crossTabbedData.push(annualTotal);

        setDataColumns(
          columns.sort((a, b) => {
            // Define the titles that should come first
            const priorityTitles = ["Month", "Median", "Minimum", "Maximum"];

            // Get the index of the titles in the priority array
            const indexA = priorityTitles.indexOf(a.title);
            const indexB = priorityTitles.indexOf(b.title);

            // If both titles are in the priority array, sort them based on their order in the array
            if (indexA !== -1 && indexB !== -1) {
              return indexA - indexB;
            }

            // If only one of the titles is in the priority array, it should come first
            if (indexA !== -1) {
              return -1;
            }

            if (indexB !== -1) {
              return 1;
            }

            // For titles not in the priority array, sort them alphabetically
            return b.title.localeCompare(a.title);
          })
        );

        return crossTabbedData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const tableColumns = [
    { title: "Location", field: "loc_name" },
    { title: "Series", field: "year_num" },
    { title: "Month", field: "month_abbrev" },
    {
      title: "Result",
      field: "reach_gain_cfs",
      render: (rowData) => (rowData.reach_gain_cfs * 1.983).toFixed(0),
    },
  ];

  const seriesColor = {
    Maximum: "#e6194b",
    Median: "#000",
    Minimum: "#4363d8",
  };

  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (data?.length > 0) {
      const indexOfLongestArray = findIndexOfLongestArray(data);
      const units = "AF / Month";
      let count = data.length;
      const graphData = {
        yLLabel: data?.length
          ? `${data[indexOfLongestArray][0]?.parameter_name} (${units})`
          : null,
        labels: data[indexOfLongestArray]?.map((item) => item.month_abbrev),
        datasets: [
          ...data.map((location) => {
            count--;
            return {
              data: location.map((item) =>
                (item.reach_gain_cfs * 1.983).toFixed(0)
              ),
              yAxisID: "yL",
              units: units,
              pointStyle: "circle",
              fill: false,
              borderWidth: ["Maximum", "Median", "Minimum"].includes(
                location[count]?.year_num
              )
                ? 3
                : 2,
              pointRadius: 0,
              pointHoverRadius: 4,
              label: location[0].year_num,
              borderColor:
                seriesColor[location[count]?.year_num] ??
                Object.values(lineColors)[count],
              backgroundColor: seriesColor[location[count]?.year_num]
                ? lighten(seriesColor[location[count]?.year_num], 0.5)
                : lighten(Object.values(lineColors)[count], 0.5),
            };
          }),
        ],
      };
      setGraphData(graphData);
    }
  }, [data]); //eslint-disable-line

  return (
    <>
      <Helmet title="Reach Gains Comparisons" />
      <Typography variant="h3" gutterBottom display="inline">
        Reach Gains Comparisons
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        {Locations && Series && (
          <>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={2}>
                    Filter Controls
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <Grid container pb={6} mt={2}>
                      <Grid item xs={12}>
                        <MultiSelect
                          name="series"
                          label="Series Lines"
                          variant="outlined"
                          valueField="series_desc"
                          displayField="series_desc"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={Series}
                          value={filterValues.series}
                          onChange={handleFilter}
                          style={{ width: "calc(100% - 8px)" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Select
                          name="location"
                          label="Locations"
                          variant="outlined"
                          valueField="loc_ndx"
                          displayField="loc_name"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={Locations}
                          value={filterValues.location}
                          onChange={handleFilter}
                          style={{ width: "calc(100% - 8px)" }}
                        />
                      </Grid>

                      <SubmitGrid item container>
                        <Grid item style={{ width: "calc(100% - 162px)" }}>
                          {!data && (
                            <Alert severity="info">
                              After selecting your inputs, click the red
                              'Submit' button to load an interactive time series
                              plot for comparison across different water years.
                            </Alert>
                          )}
                          {/*{!data?.length && (*/}
                          {/*  <Alert severity="warning">No data available</Alert>*/}
                          {/*)}*/}
                        </Grid>
                        <Grid item>
                          <SaveGraphButton
                            disabled={!data?.length}
                            ref={saveRef}
                            title="Water Year Comparison Graph"
                          />
                          <Button
                            onClick={() => {
                              refetch();
                              refetchTable();
                            }}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            size="large"
                            style={{ marginLeft: "10px" }}
                            disabled={
                              !filterValues.series.length > 0 ||
                              !filterValues.location
                            }
                          >
                            Submit
                          </Button>
                        </Grid>
                      </SubmitGrid>
                    </Grid>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>

      {Locations && (
        <>
          {data && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="time-series"
                    id="time-series"
                  >
                    <Typography variant="h4" ml={2}>
                      Graph
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <TimeseriesContainer>
                        <TableWrapper>
                          <WaterYearLineChart
                            title={
                              data?.length &&
                              `${data[0][0]?.loc_name} ${data[0][0]?.parameter_name} (AF / Month)`
                            }
                            subtitle="*Statistics are calculated for the NRCS 30-Year Hydroclimatic Normals period of 1991 to 2020"
                            data={graphData}
                            error={error}
                            isLoading={isFetching}
                            locationsOptions={Locations}
                            yLLabel={graphData?.yLLabel}
                            ref={saveRef}
                          />
                        </TableWrapper>
                      </TimeseriesContainer>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </Grid>
          )}

          {dataTable && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="table-content"
                    id="table-header"
                  >
                    <Typography variant="h4" ml={2}>
                      Monthly Reach Gains by Year
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <TableWrapper>
                        <Table
                          isLoading={isFetchingTable}
                          pageSize={30}
                          label="Monthly Reach Gains by Year"
                          columns={dataColumns}
                          data={dataTable}
                          height="100%"
                          sortArrow={<React.Fragment />}
                        />
                      </TableWrapper>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </Grid>
          )}

          {data && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="table-content"
                    id="table-header"
                  >
                    <Typography variant="h4" ml={2}>
                      Table
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <TableWrapper>
                        <Table
                          // isLoading={isLoading}
                          label="Water Years Comparison Table"
                          columns={tableColumns}
                          data={[...[].concat.apply([], data)]}
                          height="590px"
                        />
                      </TableWrapper>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ReachGainsComparison;
