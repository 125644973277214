import React, { useEffect, useMemo, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled, { ThemeProvider } from "styled-components/macro";
import debounce from "lodash.debounce";
import ReactDOM from "react-dom";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
  Grid,
  IconButton,
  Slider,
  Typography as MuiTypography,
} from "@material-ui/core";
import { MinusCircle, PlusCircle } from "react-feather";
import { useQuery } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import createTheme from "../../theme";
import Popup from "../../pages/publicMap/popup";
import ResetZoomControl from "./ResetZoomControl";
import ToggleBasemapControl from "./ToggleBasemapControl";
import Legend from "./components/Legend";
import LegendControl from "./LegendControl";
import { groupByValue } from "../../utils";
import { customSecondary } from "../../theme/variants";
import { STARTING_LOCATION } from "../../constants";
import { spacing } from "@material-ui/system";
import { create } from "jss";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SidebarSection = styled(MuiTypography)`
  ${spacing};
  color: ${() => customSecondary[500]};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(10)}px;
  opacity: 0.9;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  display: block;
`;

const MapOuterContainer = styled.div`
  height: 406px;
  width: 100%;
`;

const DUMMY_BASEMAP_LAYERS = [
  { url: "streets-v11", icon: "commute" },
  { url: "outdoors-v11", icon: "park" },
  { url: "satellite-streets-v11", icon: "satellite_alt" },
];

const monitoringLegendColors = [
  { name: `≥ 150%`, color: `#3439FC` },
  { name: `130% - 149%`, color: `#36B8EA` },
  { name: `110% - 129%`, color: `#8BF9E5` },
  { name: `90% - 109%`, color: `#6FE73F` },
  { name: `70% - 89%`, color: `#E0E53C` },
  { name: `50% - 69%`, color: `#E0AB3D` },
  { name: `< 50%`, color: `#E13A3E` },
  { name: `No data`, color: `black` },
];

const locationsLayer = {
  id: "locations",
  type: "circle",
  source: "locations",
  paint: {
    "circle-stroke-width": 2,
    "circle-stroke-color": "black",
    "circle-radius": 8,
    "circle-color": [
      "case",
      ["<", ["get", "hydroHealthPct"], 50],
      "#E0393D",
      ["<", ["get", "hydroHealthPct"], 70],
      "#E1AC3E",
      ["<", ["get", "hydroHealthPct"], 90],
      "#E1E63E",
      ["<", ["get", "hydroHealthPct"], 110],
      "#6EE53D",
      ["<", ["get", "hydroHealthPct"], 130],
      "#8AF7E3",
      ["<", ["get", "hydroHealthPct"], 150],
      "#33B6E8",
      ["<=", ["get", "hydroHealthPct"], 1000],
      "#3539FC",
      "black",
    ],
  },
  lreProperties: {
    popup: {
      titleField: "description",
      excludeFields: ["index", "description"],
    },
  },
};

const huc8Line = {
  id: "huc-8-boundaries-line",
  name: "HUC 8 Boundaries",
  type: "line",
  source: "huc-8-boundaries",
  "source-layer": "WBDHU08_UpperSnake-6vc1aa",
  paint: {
    "line-color": "white",
    "line-width": 2,
  },
  lreProperties: {
    layerGroup: "huc-8-boundaries",
  },
  drawOrder: 99,
};

const fetchWaterYears = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/hydro-health-list-water-years`
    );
    return data
      ? data.map((year) => ({
          value: -year.water_year,
          label: year.water_year.toString(),
        }))
      : [];
  } catch (err) {
    console.error(err);
  }
};

const fetchDataPoints = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/hydro-health-sites-table/`
    );
    return data.filter((location) => location.location_geometry);
  } catch (err) {
    console.error(err);
  }
};

const fetchHucData = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/hydro-health-huc-table/`
    );
    return groupByValue(data, "huc8_name");
  } catch (err) {
    console.error(err);
  }
};

const initializeMap = (mapContainerRef, setMapIsLoaded, setMap) => {
  const map = new mapboxgl.Map({
    container: mapContainerRef.current,
    style: `mapbox://styles/mapbox/${DUMMY_BASEMAP_LAYERS[0].url}`,
    center: STARTING_LOCATION,
    zoom: 6,
  });

  map.addControl(new mapboxgl.NavigationControl(), "top-left");
  map.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
      showUserHeading: true,
    }),
    "top-left"
  );
  map.addControl(new mapboxgl.FullscreenControl());
  map.addControl(new ResetZoomControl(), "top-left");

  DUMMY_BASEMAP_LAYERS.forEach((layer) =>
    map.addControl(new ToggleBasemapControl(layer.url, layer.icon))
  );

  map.on("load", () => {
    setMapIsLoaded(true);
    setMap(map);
  });

  return map;
};

const TimeseriesComparisonMap = () => {
  const theme = useSelector((state) => state.themeReducer);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [legendVisible, setLegendVisible] = useState(true);
  const [map, setMap] = useState();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const selectedYearRef = useRef(selectedYear);

  const { data: dataWaterYears } = useQuery(
    "hydro-health-list-water-years",
    fetchWaterYears,
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    data: dataPointsData,
    isLoading: dataPointsIsLoading,
    error: dataPointsError,
  } = useQuery("hydro-health-sites-table-map", fetchDataPoints, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const {
    data: hucData,
    isLoading: hucIsLoading,
    error: hucError,
  } = useQuery("hydro-health-huc-map", fetchHucData, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const matches = useMediaQuery("(min-width:600px)");

  const waterYears = useMemo(() => {
    if (!dataWaterYears) return [];
    const totalYears = dataWaterYears.length;
    const step = Math.ceil(totalYears / (matches ? 10 : 5));
    return dataWaterYears.filter((_, index) => index % step === 0);
  }, [dataWaterYears, matches]);

  const maxYear = useMemo(
    () =>
      dataWaterYears?.length > 0
        ? Math.min(...dataWaterYears.map((year) => year.value))
        : new Date().getFullYear(),
    [dataWaterYears]
  );

  const minYear = useMemo(
    () =>
      dataWaterYears?.length > 0
        ? Math.max(...dataWaterYears.map((year) => year.value))
        : new Date().getFullYear(),
    [dataWaterYears]
  );

  const handleChange = (event, newValue) => {
    selectedYearRef.current = Math.abs(newValue);
    setSelectedYear(Math.abs(newValue));
  };

  const incrementYear = () =>
    setSelectedYear((prev) => {
      selectedYearRef.current = Math.min(prev + 1, Math.abs(maxYear));
      return Math.min(prev + 1, Math.abs(maxYear));
    });
  const decrementYear = () =>
    setSelectedYear((prev) => {
      selectedYearRef.current = Math.max(prev - 1, Math.abs(minYear));
      return Math.max(prev - 1, Math.abs(minYear));
    });

  const huc8Fill = useMemo(
    () => ({
      id: "huc-8-boundaries-fill",
      name: "HUC 8 Boundaries",
      type: "fill",
      source: "huc-8-boundaries",
      "source-layer": "WBDHU08_UpperSnake-6vc1aa",
      paint: {
        "fill-color": [
          "case",
          [
            "<",
            ["coalesce", ["feature-state", selectedYear.toString()], 1001],
            50,
          ],
          "#E0393D",
          [
            "<",
            ["coalesce", ["feature-state", selectedYear.toString()], 1001],
            70,
          ],
          "#E1AC3E",
          [
            "<",
            ["coalesce", ["feature-state", selectedYear.toString()], 1001],
            90,
          ],
          "#E1E63E",
          [
            "<",
            ["coalesce", ["feature-state", selectedYear.toString()], 1001],
            110,
          ],
          "#6EE53D",
          [
            "<",
            ["coalesce", ["feature-state", selectedYear.toString()], 1001],
            130,
          ],
          "#8AF7E3",
          [
            "<",
            ["coalesce", ["feature-state", selectedYear.toString()], 1001],
            150,
          ],
          "#33B6E8",
          [
            "<=",
            ["coalesce", ["feature-state", selectedYear.toString()], 1001],
            1000,
          ],
          "#3539FC",
          "black",
        ],
        "fill-opacity": [
          "case",
          [
            "==",
            [
              "coalesce",
              ["feature-state", ["literal", "" + selectedYear]],
              1001,
            ],
            1001,
          ],
          0.2,
          0.7,
        ],
      },
      lreProperties: { layerGroup: "huc-8-boundaries" },
      drawOrder: 99,
    }),
    [selectedYear]
  );

  const popUpRef = useRef(
    new mapboxgl.Popup({ maxWidth: "400px", offset: 15, focusAfterOpen: false })
  );
  const mapContainer = useRef(null);

  useEffect(() => {
    const map = initializeMap(mapContainer, setMapIsLoaded, setMap);
    const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
    resizer.observe(mapContainer.current);
    return () => {
      resizer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (
      mapIsLoaded &&
      hucData?.length > 0 &&
      dataPointsData?.length > 0 &&
      map
    ) {
      if (!map.getSource("locations")) {
        map.addSource("huc-8-boundaries", {
          type: "vector",
          url: "mapbox://idahoswc.1rdlvyx6",
          promoteId: "Name",
        });

        hucData.forEach((row) => {
          row.forEach((item) => {
            map.setFeatureState(
              {
                source: "huc-8-boundaries",
                sourceLayer: "WBDHU08_UpperSnake-6vc1aa",
                id: item.huc8_name,
              },
              { [item.water_year]: item.hydro_health_pct }
            );
          });
        });

        map.addLayer(huc8Fill);
        map.addLayer(huc8Line);

        map.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: dataPointsData.map((location, i) => ({
              id: i,
              type: "Feature",
              properties: {
                description: location.loc_name,
                index: location.loc_ndx,
                locType: location.loc_type_name,
                huc8: location.huc8_name,
                indicator: location.indicator,
                medianIndicator: location.median_indicator,
                hydroHealthPct: location.hydro_health_pct,
                selectedYear: location.water_year,
              },
              geometry: {
                type: location.location_geometry.type,
                coordinates: location.location_geometry.coordinates,
              },
            })),
          },
        });

        map.addLayer(locationsLayer);

        map.setFilter("locations", [
          "==",
          ["get", "selectedYear"],
          selectedYear,
        ]);

        map.on("click", "huc-8-boundaries-fill", (e) => {
          const feature = map
            .queryRenderedFeatures(e.point)
            .filter((feature) => feature?.properties?.Name)[0];

          if (feature) {
            const description = feature.properties.Name;
            const value = feature.state
              ? feature.state[selectedYearRef.current]
              : "No SNOTEL data available";

            const huc8Popup = new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(
                `
        <div style="text-align: center;">
          <strong>${description}</strong>
          <div style="border-bottom: 1px solid #ccc; margin: 4px 0;"></div>
          ${value}%
        </div>
      `
              )
              .addTo(map);

            map.on("closeAllPopups", () => {
              huc8Popup.remove();
            });
          }
        });

        map.on("click", "locations", (e) => {
          map.fire("closeAllPopups");
          const features = map
            .queryRenderedFeatures(e.point)
            .filter((feature) => feature.source === "locations");
          const coordinates = [e.lngLat.lng, e.lngLat.lat];
          const popupNode = document.createElement("div");
          ReactDOM.render(
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    layers={[locationsLayer]}
                    features={features}
                    height="100%"
                    width="100%"
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        });

        map.on("mouseenter", "locations", () => {
          map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseleave", "locations", () => {
          map.getCanvas().style.cursor = "";
        });
      }
    }
  }, [
    dataPointsIsLoading,
    hucIsLoading,
    mapIsLoaded,
    map,
    dataPointsData,
    hucData,
    huc8Fill,
    selectedYear,
    theme.currentTheme,
  ]);

  useEffect(() => {
    if (map && map.getLayer("locations")) {
      map.setFilter("locations", ["==", ["get", "selectedYear"], selectedYear]);
      map.setPaintProperty("huc-8-boundaries-fill", "fill-color", [
        "case",
        [
          "<",
          ["coalesce", ["feature-state", selectedYear.toString()], 1001],
          50,
        ],
        "#E0393D",
        [
          "<",
          ["coalesce", ["feature-state", selectedYear.toString()], 1001],
          70,
        ],
        "#E1AC3E",
        [
          "<",
          ["coalesce", ["feature-state", selectedYear.toString()], 1001],
          90,
        ],
        "#E1E63E",
        [
          "<",
          ["coalesce", ["feature-state", selectedYear.toString()], 1001],
          110,
        ],
        "#6EE53D",
        [
          "<",
          ["coalesce", ["feature-state", selectedYear.toString()], 1001],
          130,
        ],
        "#8AF7E3",
        [
          "<",
          ["coalesce", ["feature-state", selectedYear.toString()], 1001],
          150,
        ],
        "#33B6E8",
        [
          "<=",
          ["coalesce", ["feature-state", selectedYear.toString()], 1001],
          1000,
        ],
        "#3539FC",
        "black",
      ]);
    }
  }, [map, selectedYear]);

  if (dataPointsError)
    return `An error has occurred: ${dataPointsError.message}`;

  if (hucError) return `An error has occurred: ${hucError.message}`;

  return (
    <>
      <Grid item xs={12}>
        <MapOuterContainer>
          <Root>
            <MapContainer ref={mapContainer} />
            {legendVisible && <Legend legendColors={monitoringLegendColors} />}
            <LegendControl
              open={legendVisible}
              onToggle={() => setLegendVisible(!legendVisible)}
            />
          </Root>
        </MapOuterContainer>
      </Grid>
      <Grid item xs={12} pt={2}>
        <SidebarSection>
          Select a Year to Visualize its Data on the Map
        </SidebarSection>
        <Grid container spacing={10} pl={3} pr={3}>
          <Grid item>
            <IconButton onClick={incrementYear}>
              <PlusCircle />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Slider
              id="selectedYear"
              valueLabelDisplay="on"
              value={-selectedYear}
              step={1}
              min={maxYear}
              max={minYear}
              marks={waterYears}
              onChange={handleChange}
              scale={(x) => -x}
              track={false}
            />
          </Grid>
          <Grid item>
            <IconButton onClick={decrementYear}>
              <MinusCircle />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TimeseriesComparisonMap;
