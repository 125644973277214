import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import HydrologicHealthMap from "../../../components/map/HydrologicHealthMap";
import { useQuery } from "react-query";
import axios from "axios";
import Table from "../../../components/Table";
import { groupByValue, toFixedWithoutRounding } from "../../../utils";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const cellColorBackground = (value) => {
  if (value === null) return "black";
  if (value < 50) return "#E0393D";
  if (value < 70) return "#E1AC3E";
  if (value < 90) return "#E1E63E";
  if (value < 110) return "#6EE53D";
  if (value < 130) return "#8AF7E3";
  if (value < 150) return "#33B6E8";
  if (value <= 1000) return "#3539FC";
  return "black";
};

const cellColor = (value) => {
  if (value < 50) return "white";
  if (value < 70) return "black";
  if (value < 90) return "black";
  if (value < 110) return "black";
  if (value < 130) return "black";
  if (value < 150) return "black";
  if (value <= 1000) return "white";
  return "black";
};

const createColumn = (year) => ({
  title: "" + year,
  field: "" + year,
  render: (rowData) =>
    rowData[year] === 0 || rowData[year]
      ? `${toFixedWithoutRounding(rowData[year], 0)}%`
      : null,
  cellStyle: (e, rowData) => ({
    padding: "3px",
    backgroundColor: cellColorBackground(
      isNaN(rowData[year]) ? null : rowData[year]
    ),
    color: cellColor(isNaN(rowData[year]) ? null : rowData[year]),
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  }),
  headerStyle: {
    padding: "3px",
    textAlign: "center",
    fontWeight: 900,
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
});

const useDataPointsTableData = () => {
  const [columns, setColumns] = useState([
    {
      title: "Location",
      field: "location_name",
      cellStyle: {
        whiteSpace: "nowrap",
        padding: "3px",
        position: "sticky",
        left: 0,
        background: "white",
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      },
      headerStyle: {
        padding: "3px",
        fontWeight: 900,
        position: "sticky",
        left: 0,
        background: "white",
        zIndex: 11,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      },
      position: "sticky",
      left: 0,
      background: "white",
    },
    {
      title: "HUC8",
      field: "huc8_name",
      cellStyle: {
        whiteSpace: "nowrap",
        padding: "3px",
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      },
      headerStyle: {
        padding: "3px",
        fontWeight: 900,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      },
      defaultSort: "asc",
    },
  ]);

  const { data, isLoading, error } = useQuery(
    "hydro-health-sites-table",
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/hydro-health-sites-table/`
      );
      const groupedData = groupByValue(data, "loc_name");

      const crossTabbedData = groupedData.map((location) => {
        const obj = {
          location_name: location[0].loc_name,
          huc8_name: location[0].huc8_name,
        };
        location.forEach((item) => {
          if (item?.water_year) {
            obj[item.water_year] = item.hydro_health_pct;
            if (!columns.find((col) => col.title === "" + item.water_year)) {
              columns.push(createColumn(item.water_year));
            }
          }
        });
        return obj;
      });
      setColumns(columns.sort((a, b) => b.title - a.title));
      return crossTabbedData;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return { data, isLoading, error, columns };
};

const useHucTableData = () => {
  const [columns, setColumns] = useState([
    {
      title: "HUC8",
      field: "huc8_name",
      cellStyle: {
        whiteSpace: "nowrap",
        padding: "3px",
        position: "sticky",
        left: 0,
        background: "white",
      },
      headerStyle: {
        padding: "3px",
        fontWeight: 900,
        position: "sticky",
        left: 0,
        background: "white",
        zIndex: 11,
      },
      defaultSort: "asc",
      position: "sticky",
      left: 0,
      background: "white",
    },
  ]);

  const { data, isLoading, error } = useQuery(
    "hydro-health-huc-table",
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/hydro-health-huc-table/`
      );
      const groupedData = groupByValue(data, "huc8_name");

      const crossTabbedData = groupedData.map((huc) => {
        const obj = {
          huc8_name: huc[0].huc8_name,
        };
        huc.forEach((item) => {
          if (item?.water_year) {
            obj[item.water_year] = item.hydro_health_pct;
            if (!columns.find((col) => col.title === "" + item.water_year)) {
              columns.push(createColumn(item.water_year));
            }
          }
        });
        return obj;
      });
      setColumns(columns.sort((a, b) => b.title - a.title));
      return crossTabbedData;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return { data, isLoading, error, columns };
};

const HydrologicHealth = () => {
  const {
    data: dataPointsTableData,
    isLoading: dataPointsTableDataIsLoading,
    error: dataPointsTableDataError,
    columns: dataPointsTableDataColumns,
  } = useDataPointsTableData();

  const {
    data: hucTableData,
    isLoading: hucTableDataIsLoading,
    error: hucTableDataError,
    columns: hucTableDataColumns,
  } = useHucTableData();

  if (dataPointsTableDataError)
    return "An error has occurred: " + dataPointsTableDataError.message;
  if (hucTableDataError)
    return "An error has occurred: " + hucTableDataError.message;

  return (
    <>
      <Helmet title="SNOTEL SWE Data" />
      <Typography variant="h3" gutterBottom display="inline">
        SNOTEL SWE Data
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container pb={2} mt={2}>
                <HydrologicHealthMap />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Data Points Table
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    isLoading={dataPointsTableDataIsLoading}
                    pageSize={30}
                    label="Data Points SNOTEL SWE Data"
                    columns={dataPointsTableDataColumns}
                    data={dataPointsTableData}
                    height="100%"
                    sortArrow={<React.Fragment />}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                HUC8 Table
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    isLoading={hucTableDataIsLoading}
                    pageSize={30}
                    label="HUC8 SNOTEL SWE Data"
                    columns={hucTableDataColumns}
                    data={hucTableData}
                    height="100%"
                    sortArrow={<React.Fragment />}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default HydrologicHealth;
