import { useState } from "react";
import { scaleOrdinal } from "d3-scale";
import { scaleSequential } from "d3-scale";
import { interpolateRainbow } from "d3-scale-chromatic";

const locationTypesValues = [
  "Diversion",
  "Diversion Pump",
  "Non-Sentinel Well",
  "Recharge",
  "Reservoir",
  "Return Flow",
  "SNOTEL",
  "Sentinel Well",
  "Spring",
  "Stream Gage",
  "Stream Reach",
  "Weather Station",
];

const parameterNamesValues = [
  "Average Daily Gain",
  "Avg Air Temp",
  "Computed Average Reservoir Inflow",
  "Cumulative WY Discharge",
  "Cumulative WY Precip",
  "Daily Precipitation",
  "Depth to Water Level",
  "Discharge",
  "Estimated Average Unregulated Flow",
  "Evapotranspiration",
  "Gage Height",
  "Growing Degree Days (50 Deg)",
  "Max Air Temp",
  "Reach Actual Flow",
  "Reach Gain",
  "Reach Natural Flow",
  "Reach Stored Flow",
  "Recharge",
  "Reservoir Storage",
  "Return Flow",
  "SWE",
  'Soil Moisture (Avg % at 8")',
  "Totalled Average Discharge",
  "Water Surface Elevation",
];

const dataProvidersValues = [
  "Calculation",
  "IDWR",
  "IDWR Accounting",
  "IDWR AquaInfo",
  "IDWR IWRB and IGWA",
  "NRCS Snotel",
  "USBR AgriMet",
  "USBR HydroMet",
  "USGS",
];

const huc8NamesValues = [
  "American Falls",
  "Beaver-Camas",
  "Big Lost",
  "Blackfoot",
  "Goose",
  "Idaho Falls",
  "Lake Walcott",
  "Lemhi",
  "Little Lost",
  "Little Wood",
  "Lower Henrys",
  "Middle Snake-Succor",
  "Palisades",
  "Portneuf",
  "Raft",
  "Snake Headwaters",
  "Teton",
  "Upper Henrys",
  "Upper Snake-Rock",
  "Willow",
];

const huc10NamesValues = [
  "American Falls Reservoir",
  "Big Cottonwood Creek",
  "Birch Creek-Snake River",
  "Black Ridge Crater",
  "Box Canyon",
  "Brigham Point-Snake River",
  "Calder Creek-Raft River",
  "Camas Creek",
  "Camp Holly Lake-Town of Rupert",
  "Cassia Creek",
  "Cedar Butte",
  "City of Aberdeen",
  "City of Shelley-Snake River",
  "Coulter Creek-Snake River",
  "Crater Lake",
  "East Main Canal-Little Wood River",
  "Fish Creek",
  "Garden Creek-Marsh Creek",
  "Headwaters Camas Creek",
  "Headwaters Willow Creek",
  "Island Park Reservoir-Henrys Fork",
  "Kettle Butte",
  "Laidlaw Park",
  "Lanes Creek-Diamond Creek",
  "Little Fish Creek-Little Wood River",
  "Lower Bannock Creek",
  "Lower Blackfoot River",
  "Lower Fall River",
  "Lower Little Lost River",
  "Lower Portneuf River",
  "Lyons Creek-Snake River",
  "Massacre Rocks-Snake River",
  "Middle Big Lost River",
  "Middle Goose Creek",
  "Middle Portneuf River",
  "Milner Dam-Snake River",
  "Moose Creek-Snake River",
  "Mud Creek-Snake River",
  "Muldoon Creek",
  "North Fork Big Lost River",
  "Outlet Willow Creek",
  "Pleasant Valley-Lake Channel Canyon",
  "Rabbit Creek-Snake River",
  "Rising River-Watson Slough",
  "Rock Creek",
  "Ross Fork",
  "Sand Creek",
  "Sand Creek-Henrys Fork",
  "Snake River-Snake River",
  "South Teton River-Teton River",
  "Star Hope Creek",
  "Teton Basin-Teton River",
  "Texas Creek",
  "Town of Springfield-Danielson Creek",
  "Trail Creek-Teton River",
  "Twin Falls Main Canal-Snake River",
  "Upper Blackfoot River",
  "Wet Creek",
];

const gwDistrictsValues = [
  "Aberdeen-American Falls Ground Water District",
  "Big Lost River Ground Water District",
  "Bingham Ground Water District",
  "Bonneville-Jefferson Ground Water District",
  "Carey Valley Ground Water District",
  "Henrys Fork Ground Water District",
  "Jefferson Clark Ground Water District",
  "Magic Valley Ground Water District",
  "North Snake Ground Water District",
  "Raft River Ground Water District",
  "No Ground Water District",
];

const colors = (specifier) => {
  var n = (specifier.length / 6) | 0,
    colors = new Array(n),
    i = 0;
  while (i < n) colors[i] = "#" + specifier.slice(i * 6, ++i * 6);
  return colors;
};

const colorsString20 =
  "1F77B4AEC7E8FF7F0EFFBB782CA02C98DF8AD62728FF98969467BDC5B0D58C564BC49C94E377C2F7B6D27F7F7FC7C7C7BCBD22DBDB8D17BECF9EDAE5";

const buildScale = (values) => {
  const scale =
    values.length <= 20
      ? scaleOrdinal(colors(colorsString20))
      : scaleSequential(interpolateRainbow);

  const degree = 100 / values.length / 100;

  return values.reduce((acc, val, i) => {
    acc.push([val]);

    if (values.length <= 20) {
      acc.push(scale(val));
    } else {
      acc.push(scale(i * degree));
    }

    return acc;
  }, []);
};

const layerId = "data-points-circle";
export const styleValues = {
  locationTypes: {
    id: "locationTypes",
    layerId,
    layerFieldName: "loc_type_name",
    name: "Location Types",
    paint: {
      "circle-color": [
        "match",
        ["get", "loc_type_name"],
        ...buildScale(locationTypesValues),
        "#000000",
      ],
    },
  },
  parameterNames: {
    id: "parameterNames",
    layerId,
    layerFieldName: "parameter_name",
    name: "Parameters",
    paint: {
      "circle-color": [
        "match",
        ["get", "parameter_name"],
        ...buildScale(parameterNamesValues),
        "#000000",
      ],
    },
  },
  dataProviders: {
    id: "dataProviders",
    layerId,
    layerFieldName: "data_provider",
    name: "Data Providers",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "data_provider"],
        ...buildScale(dataProvidersValues),
        "#000000",
      ],
    },
  },
  huc8Names: {
    id: "huc8Names",
    layerId,
    layerFieldName: "huc8_name",
    name: "HUC 8s",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "huc8_name"],
        ...buildScale(huc8NamesValues),
        "#000000",
      ],
    },
  },
  huc10Names: {
    id: "huc10Names",
    layerId,
    layerFieldName: "huc10_name",
    name: "HUC 10s",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "huc10_name"],
        ...buildScale(huc10NamesValues),
        "#000000",
      ],
    },
  },
  gwDistricts: {
    id: "gwDistricts",
    layerId,
    layerFieldName: "gw_district",
    name: "GW Districts",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "gw_district"],
        ...buildScale(gwDistrictsValues),
        "#000000",
      ],
    },
  },
  default: {
    id: "default",
    layerId,
    layerFieldName: "",
    name: "Default",
    paint: {
      "circle-color": "#1e8dd2",
    },
  },
};

const useLayerStyles = ({ onLayerStyleChange }) => {
  const [activeStyle, setActiveStyle] = useState(styleValues.locationTypes);
  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
