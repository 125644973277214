export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-112.80227, 43.52744];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 7,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "data-points-circle";
export const WELLS_LABELS_LAYER_ID = "data-points-symbol";

export const INIT_FILTER_VALUES = {
  locationTypes: {
    label: "Location Types",
    name: "locationTypes",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "loc_type_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  parameterNames: {
    label: "Parameters",
    name: "parameterNames",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "parameter_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  dataProviders: {
    label: "Data Providers",
    name: "dataProviders",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "data_provider",
    options: [],
    type: "multi-select",
    value: [],
  },
  huc8Names: {
    label: "HUC 8s",
    name: "huc8Names",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "huc8_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  huc10Names: {
    label: "HUC 10s",
    name: "huc10Names",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "huc10_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  gwDistricts: {
    label: "Groundwater Districts",
    name: "gwDistricts",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "gw_district",
    options: [],
    type: "multi-select",
    value: [],
  },

  search: {
    layerId: WELLS_LAYER_ID,
    type: "select",
    value: "attributes_search",
  },
};

export const WELLS_SEARCH_OPTIONS = [
  {
    value: "attributes_search",
    label: "Attributes",
  },
  {
    value: "comma_separated_data_dots_search",
    label: "Ids",
  },
];
