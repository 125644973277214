import React, { useRef, useState } from "react";
import { useQueries, useQuery } from "react-query";
import axios from "axios";
import { MultiSelect } from "@lrewater/lre-react";
import { groupByValue, lineColors } from "../../../utils";
import Button from "@material-ui/core/Button";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  lighten,
  Typography as MuiTypography,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import { spacing } from "@material-ui/system";
import { customSecondary } from "../../../theme/variants";
import { Helmet } from "react-helmet-async";
import WaterYearLineChart from "../../../components/graphs/WaterYearLineChart";
import ForecastSupplyMap from "../../../components/map/ForecastSupplyMap";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const SidebarSection = styled(MuiTypography)`
  ${spacing};
  color: ${() => customSecondary[500]};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  display: block;
`;

const MapContainer = styled.div`
  height: 312px;
  width: 100%;
`;

const ForecastSupply = () => {
  const saveRef = useRef(null);
  const [filterValues, setFilterValues] = useState({
    series: [
      "Minimum",
      "Median",
      "Maximum",
      (new Date().getFullYear() + 1).toString(),
      new Date().getFullYear().toString(),
    ],
  });

  const config = [
    {
      queryKey: 1,
      parameterName: "Discharge",
      parameterNdx: "2",
      LocationName: "BOX CANYON SPRINGS NR WENDELL ID",
      locationNdx: "178",
    },
    {
      queryKey: 2,
      parameterName: "Cumulative WY Discharge",
      parameterNdx: "200",
      LocationName: "SPRING CREEK AT SHEEPSKIN RD NR FORT HALL ID",
      locationNdx: "46",
    },
    {
      queryKey: 3,
      parameterName: "Cumulative WY Discharge",
      parameterNdx: "200",
      LocationName: "Snake R nr Heise",
      locationNdx: "79",
    },
    {
      queryKey: 4,
      parameterName: "SWE",
      parameterNdx: "14",
      LocationName: "Two Ocean Plateau",
      locationNdx: "173",
    },
    {
      queryKey: 5,
      parameterName: "Reservoir Storage",
      parameterNdx: "5",
      LocationName: "American Falls Reservoir at American Falls, ID",
      locationNdx: "25",
    },
    {
      queryKey: 6,
      parameterName: "Depth to Water Level",
      parameterNdx: "7",
      LocationName: "05S31E27ABA1",
      locationNdx: "15",
      yLReverse: true,
    },
  ];

  const { data: Series } = useQuery(
    ["wy-analysis-dropdown-series"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-dropdown-series`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterValues((prevState) => {
      let newValues = { ...prevState };

      newValues[name] = value;

      return newValues;
    });
  };

  async function fetchQuery(query) {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-comparison/${query.parameterNdx}/${query.locationNdx}/${filterValues.series}`
      );

      const groupedData = groupByValue(data, "series_desc");

      const graphData = createGraphData(groupedData);

      return graphData;
    } catch (err) {
      console.error(err);
    }
  }

  const [toggleRefetch, setToggleRefetch] = useState(false);
  const results = useQueries(
    config.map((query) => {
      return {
        queryKey: [query.queryKey, toggleRefetch],
        queryFn: () => fetchQuery(query),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: true,
      };
    })
  );

  const isLoading = results.some((query) => query.isFetching);
  const error = results.some((query) => query.error);

  const seriesColor = {
    Maximum: "#e6194b",
    Median: "#000",
    Minimum: "#4363d8",
  };

  const createGraphData = (data) => {
    if (data?.length > 0) {
      let count = data.length;
      const graphData = {
        yLLabel: data?.length
          ? `${data[0][0]?.parameter_name} (${data[0][0]?.units_name})`
          : null,
        title: data?.length
          ? `${data[0][0]?.loc_name}, ${data[0][0]?.parameter_name} (${data[0][0]?.units_name})`
          : null,
        labels: data[0]?.map((item) => item.day_desc),
        datasets: [
          ...data.map((location) => {
            count--;
            return {
              data: location.map((item) => item.rvalue),
              yAxisID: "yL",
              units: location[0].units_name,
              pointStyle: "circle",
              fill: false,
              borderWidth: ["Maximum", "Median", "Minimum"].includes(
                location[count]?.series_desc
              )
                ? 3
                : 2,
              pointRadius: 0,
              pointHoverRadius: 4,
              label: location[0].series_desc,
              borderColor:
                seriesColor[location[count]?.series_desc] ??
                Object.values(lineColors)[count],
              backgroundColor: seriesColor[location[count]?.series_desc]
                ? lighten(seriesColor[location[count]?.series_desc], 0.5)
                : lighten(Object.values(lineColors)[count], 0.5),
            };
          }),
        ],
      };
      return graphData;
    }
  };

  return (
    <>
      <Helmet title="Forecast Supply" />
      <Typography variant="h3" gutterBottom display="inline">
        Forecast Supply
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={12} xl={5}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <ForecastSupplyMap
                  SelectedLocations={config.map(
                    (location) => +location.locationNdx
                  )}
                />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {Series && (
          <>
            <Grid item xs={12} md={12} lg={12} xl={7}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={2}>
                    Filter Controls
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <Grid container pb={6} mt={2}>
                      <Grid item xs={12}>
                        <SidebarSection>Series Lines</SidebarSection>
                        <MultiSelect
                          name="series"
                          label="Series"
                          variant="outlined"
                          valueField="series_desc"
                          displayField="series_desc"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={Series}
                          value={filterValues.series}
                          onChange={handleFilter}
                          style={{ width: "calc(100% - 8px)" }}
                        />
                      </Grid>

                      <SubmitGrid item container>
                        <Grid item style={{ width: "calc(100% - 162px)" }} />
                        <Grid item>
                          <Button
                            onClick={() =>
                              setToggleRefetch((prevState) => {
                                prevState = !prevState;
                                return prevState;
                              })
                            }
                            type="submit"
                            color="secondary"
                            variant="contained"
                            size="large"
                            style={{ marginLeft: "10px" }}
                            disabled={!filterValues.series.length > 0}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </SubmitGrid>
                    </Grid>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>

      {results && (
        <Grid container spacing={6}>
          {results.map((result, i) => {
            return (
              <Grid key={config[i].queryKey} item md={12} lg={6}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    style={{ height: "45px" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="time-series"
                    id="time-series"
                  >
                    <Typography variant="h4" ml={2}>
                      {result.data?.title}
                    </Typography>
                  </AccordionSummary>
                  <Box>
                    <AccordionDetails>
                      <TimeseriesContainer>
                        <TableWrapper>
                          <WaterYearLineChart
                            // title={result.data?.title}
                            yLReverse={config[i].yLReverse}
                            subtitle="*Statistics are calculated for the NRCS 30-Year Hydroclimatic Normals period of 1991 to 2020"
                            data={result.data}
                            error={error}
                            isLoading={isLoading}
                            yLLabel={result.data?.yLLabel}
                            ref={saveRef}
                          />
                        </TableWrapper>
                      </TimeseriesContainer>
                    </AccordionDetails>
                  </Box>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default ForecastSupply;
