import React, { useCallback, useMemo, useRef, useState } from "react";
import { useQueries, useQuery } from "react-query";
import axios from "axios";
import { MultiSelect } from "@lrewater/lre-react";
import { groupByValue, lineColors } from "../../../utils";
import Button from "@material-ui/core/Button";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  lighten,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography as MuiTypography,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { spacing } from "@material-ui/system";
import { customSecondary } from "../../../theme/variants";
import { Helmet } from "react-helmet-async";
import WaterYearLineChart from "../../../components/graphs/WaterYearLineChart";
import TributaryForecastMap from "../../../components/map/TributaryForecastMap";
import { Alert } from "@material-ui/lab";
import {
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon,
} from "@material-ui/icons";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled("div")`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const GraphBox = styled(Box)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
`;

const ListContainer = styled("div")`
  width: 100%;
  padding: 8px;
  margin: 8px;
  border: solid 1px #e0e0e0;
  border-radius: 4px;
`;

const CustomAlert = styled(Alert)`
  margin-bottom: 8px;
`;

const CustomListItem = styled(ListItem)`
  margin-left: 16px;
`;

const TimeseriesContainer = styled("div")`
  height: 600px;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const SidebarSection = styled(MuiTypography)`
  ${spacing};
  color: ${() => customSecondary[500]};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  display: block;
`;

const MapContainer = styled("div")`
  height: 312px;
  width: 100%;
`;

const CONFIG = [
  {
    queryKey: 1,
    parameterName: "Reach Gain",
    parameterNdx: "1",
    LocationName: "BLACKFOOT TO NEELEY",
    locationNdx: "53",
    reach: "Near Blackfoot to Neeley (nrBF-N) -- Portneuf & American Falls",
  },
  {
    queryKey: 2,
    parameterName: "Cumulative WY Discharge",
    parameterNdx: "200",
    LocationName: "PORTNEUF RIVER NR TYHEE ID",
    locationNdx: "51",
    reach: "Near Blackfoot to Neeley (nrBF-N) -- Portneuf & American Falls",
  },
  {
    queryKey: 3,
    parameterName: "Cumulative WY Discharge",
    parameterNdx: "200",
    LocationName: "Bannock Creek nr Pocatello, ID",
    locationNdx: "181",
    reach: "Near Blackfoot to Neeley (nrBF-N) -- Portneuf & American Falls",
  },
  {
    queryKey: 4,
    parameterName: "SWE",
    parameterNdx: "14",
    LocationName: "Sedgwick Peak",
    locationNdx: "95",
    reach: "Near Blackfoot to Neeley (nrBF-N) -- Portneuf & American Falls",
  },
  {
    queryKey: 5,
    parameterName: "Reach Gain",
    parameterNdx: "1",
    LocationName: "NEELEY TO MINIDOKA",
    locationNdx: "52",
    reach: "Neeley to Minidoka (N-M) -- Raft River",
  },
  {
    queryKey: 6,
    parameterName: "Cumulative WY Discharge",
    parameterNdx: "200",
    LocationName: "Raft River nr Mouth at Yale Raft River, ID",
    locationNdx: "182",
    reach: "Neeley to Minidoka (N-M) -- Raft River",
  },
  {
    queryKey: 7,
    parameterName: "Cumulative WY Discharge",
    parameterNdx: "200",
    LocationName: "Cassia Creek below Blacksmith Ck, ID",
    locationNdx: "183",
    reach: "Neeley to Minidoka (N-M) -- Raft River",
  },
  {
    queryKey: 8,
    parameterName: "Reach Gain",
    parameterNdx: "1",
    LocationName: "MINIDOKA TO MILNER",
    locationNdx: "54",
    reach: "Minidoka to Milner (M-M) - Goose Creek",
  },
  {
    queryKey: 9,
    parameterName: "Cumulative WY Discharge",
    parameterNdx: "200",
    LocationName: "Goose Creek above Trapper Creek nr Oakley, ID",
    locationNdx: "184",
    reach: "Minidoka to Milner (M-M) - Goose Creek",
  },
  {
    queryKey: 10,
    parameterName: "Cumulative WY Discharge",
    parameterNdx: "200",
    LocationName: "Trapper Creek nr Oakley, ID",
    locationNdx: "185",
    reach: "Minidoka to Milner (M-M) - Goose Creek",
  },
  {
    queryKey: 11,
    parameterName: "SWE",
    parameterNdx: "14",
    LocationName: "Bostetter R.S.",
    locationNdx: "113",
    reach: "Minidoka to Milner (M-M) - Goose Creek",
  },
];

const LINKS_CONFIG = {
  "Near Blackfoot to Neeley (nrBF-N) -- Portneuf & American Falls": [
    {
      label: "Portneuf River Basin SWE plot",
      url: "https://nwcc-apps.sc.egov.usda.gov/awdb/basin-plots/POR/WTEQ/assocHUCid2_8/portneuf.html",
    },
    {
      label: "Portneuf at Topaz Snow to Flow plot",
      url: "https://usbr.gov/uc/water/hydrodata/stf/Upper%20Snake/Portneuf%20R%20at%20Topaz.html",
    },
  ],
  "Neeley to Minidoka (N-M) -- Raft River": [
    {
      label: "Raft River Basin SWE plot",
      url: "https://nwcc-apps.sc.egov.usda.gov/awdb/basin-plots/POR/WTEQ/assocHUCid2_8/raft.html",
    },
  ],
  "Minidoka to Milner (M-M) - Goose Creek": [
    {
      label: "Goose Creek nr Oakley Snow to Flow plot",
      url: "https://usbr.gov/uc/water/hydrodata/stf/Upper%20Snake/Goose%20Ck%20abv%20Trapper%20Ck%20nr%20Oakley.html",
    },
    {
      label: "Trapper Creek nr Oakley Snow to Flow plot",
      url: "https://usbr.gov/uc/water/hydrodata/stf/Upper%20Snake/Trapper%20Ck%20nr%20Oakley.html",
    },
    {
      label: "Goose Creek Basin SWE plot",
      url: "https://nwcc-apps.sc.egov.usda.gov/awdb/basin-plots/POR/WTEQ/assocHUCid2_8/goose-trapper.html",
    },
  ],
};

const DEFAULT_FILTER_VALUES = {
  series: [
    "Minimum",
    "Median",
    "Maximum",
    (new Date().getFullYear() + 1).toString(),
    new Date().getFullYear().toString(),
  ],
};

const SERIES_COLORS = {
  Maximum: "#e6194b",
  Median: "#000",
  Minimum: "#4363d8",
};

const createGraphData = (data) => {
  if (data?.length > 0) {
    let count = data.length;
    const graphData = {
      yLLabel: data?.length
        ? `${data[0][0]?.parameter_name} (${data[0][0]?.units_name})`
        : null,
      title: data?.length
        ? `${data[0][0]?.loc_name}, ${data[0][0]?.parameter_name} (${data[0][0]?.units_name})`
        : null,
      labels: data[0]?.map((item) => item.day_desc),
      datasets: [
        ...data.map((location) => {
          count--;
          return {
            data: location.map((item) => item.rvalue),
            yAxisID: "yL",
            units: location[0].units_name,
            pointStyle: "circle",
            fill: false,
            borderWidth: ["Maximum", "Median", "Minimum"].includes(
              location[count]?.series_desc
            )
              ? 3
              : 2,
            pointRadius: 0,
            pointHoverRadius: 4,
            label: location[0].series_desc,
            borderColor:
              SERIES_COLORS[location[count]?.series_desc] ??
              Object.values(lineColors)[count],
            backgroundColor: SERIES_COLORS[location[count]?.series_desc]
              ? lighten(SERIES_COLORS[location[count]?.series_desc], 0.5)
              : lighten(Object.values(lineColors)[count], 0.5),
          };
        }),
      ],
    };
    return graphData;
  }
};

async function fetchQuery(query, series) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-comparison/${query.parameterNdx}/${query.locationNdx}/${series}`
    );

    const groupedData = groupByValue(data, "series_desc");

    const graphData = createGraphData(groupedData);

    return graphData;
  } catch (err) {
    console.error(err);
  }
}

const TributaryForecast = () => {
  const saveRef = useRef(null);
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER_VALUES);

  const handleFilter = useCallback((event) => {
    const { name, value } = event.target;
    setFilterValues((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const { data: Series } = useQuery(
    ["wy-analysis-dropdown-series"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-dropdown-series`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [toggleRefetch, setToggleRefetch] = useState(false);
  const results = useQueries(
    CONFIG.map((query) => {
      return {
        queryKey: [query.queryKey, toggleRefetch],
        queryFn: () => fetchQuery(query, filterValues.series),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: true,
      };
    })
  );

  const groupedResults = useMemo(() => {
    return results.reduce((acc, result, index) => {
      const reach = CONFIG[index].reach;
      if (!acc[reach]) {
        acc[reach] = [];
      }
      acc[reach].push({ ...result, CONFIG: CONFIG[index] });
      return acc;
    }, {});
  }, [results]);

  const MoreInfo = ({ reach }) => {
    return (
      <ListContainer>
        <CustomAlert icon={<InfoIcon />} severity="info">
          More Info for <strong>{reach}:</strong>
        </CustomAlert>
        <List>
          {LINKS_CONFIG[reach].map((link) => (
            <CustomListItem key={link.url}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.label}
                  </Link>
                }
              />
            </CustomListItem>
          ))}
        </List>
      </ListContainer>
    );
  };

  return (
    <>
      <Helmet title="Tributary Forecast" />
      <Typography variant="h3" gutterBottom display="inline">
        Tributary Forecast (Coming Soon)
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={12} xl={5}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <TributaryForecastMap
                  SelectedLocations={CONFIG.map(
                    (location) => +location.locationNdx
                  )}
                />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {Series && (
          <>
            <Grid item xs={12} md={12} lg={12} xl={7}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={2}>
                    Filter Controls
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container pb={6} mt={2}>
                    <Grid item xs={12}>
                      <SidebarSection>Series Lines</SidebarSection>
                      <MultiSelect
                        name="series"
                        label="Series"
                        variant="outlined"
                        valueField="series_desc"
                        displayField="series_desc"
                        outlineColor="primary"
                        labelColor="primary"
                        margin="normal"
                        data={Series}
                        value={filterValues.series}
                        onChange={handleFilter}
                        style={{ width: "calc(100% - 8px)" }}
                      />
                    </Grid>

                    <SubmitGrid item container>
                      <Grid item style={{ flexGrow: 1 }} />
                      <Grid item>
                        <Button
                          onClick={() =>
                            setToggleRefetch((prevState) => {
                              prevState = !prevState;
                              return prevState;
                            })
                          }
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          disabled={!filterValues.series.length > 0}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </SubmitGrid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>

      {results && (
        <Grid container spacing={6}>
          {Object.entries(groupedResults).map(([reach, results]) => (
            <Grid item xs={12} key={reach}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h4">{reach}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {results.map((result, i) => (
                      <Grid item xs={12} lg={6} key={i}>
                        <GraphBox>
                          <Typography
                            variant="h6"
                            style={{
                              height: "40px",
                              marginBottom: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {result.data?.title}
                          </Typography>
                          <TimeseriesContainer>
                            <TableWrapper>
                              <WaterYearLineChart
                                yLReverse={CONFIG[i].yLReverse}
                                subtitle="*Statistics are calculated for the NRCS 30-Year Hydroclimatic Normals period of 1991 to 2020"
                                data={result.data}
                                error={result.error}
                                isLoading={result.isLoading}
                                yLLabel={result.data?.yLLabel}
                                ref={saveRef}
                              />
                            </TableWrapper>
                          </TimeseriesContainer>
                        </GraphBox>
                      </Grid>
                    ))}
                    <MoreInfo reach={reach} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default TributaryForecast;
